import { LitElement, html } from "lit-element";
import { subscribe, unsubscribe } from "redux-subscribe";
import clonedeep from "lodash-es/cloneDeep";

import store from "../store";
import { territoryUpdateVolumeSelections, territoryUpdateRoundsSelection } from "../core/actions/territoryActions";
import { getTerritoryById, territoryActive } from "../core/selectors/territorySelector";
import { noop, roundsNetworkToggle } from "../core/actions/actions";
import guid from "../services/guid";

import { GMAP_ROUNDS_LAYERS, reduxActiveRoundsUpdateEvent } from "../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from "../const/volumeSlices";
import { layerSubtotal } from "../services/volumes";
import { isTerritoryTargeted } from "../core/selectors/targetingSelector";
import { emptyWalksVolume, adjustSelectionToRatio } from "../core/selectors/roundSelector";
import { targetActiveTerritory } from "../services/httpApis/targetingQuery";
import { nothing } from "lit-html";
const {
    INCLUSIVE,
    EXCLUSIVE,
    RESIDENTIAL,
    BUSINESS,
    FARMER,
    DAIRY,
    NEWSPAPER_EXCLUSIVE,
    NEWSPAPER_INCLUSIVE,
    NEWSPAPER_ALL,
} = VOLUME_SLICES;

export class TerritoryMetaTableComponent extends LitElement {
    constructor() {
        super();
        this.id = `rm_${guid()}`;
    }

    static get properties() {
        return {
            territory: { type: Object },
            id: { type: String },
            emptiesThreshold: { type: String },
            clientView: { type: Boolean },
        };
    }

    firstUpdated() {
        store.dispatch(
            subscribe("territories", `updateAndRenderTable-${this.id}`, () => {
                if (!getTerritoryById(this.territory.id)) {
                    this.destroy();
                }
                return noop(`updateAndRenderTable-${this.id}`);
            })
        );

        store.dispatch(
            subscribe("roundsData", `roundsDataSyncTerritory-${this.territory.id}`, () => {
                // include only active territory to the update
                if (territoryActive(this.territory.id)) {
                    this.requestUpdate();
                }
                return noop(`roundsDataSyncTerritory-${this.territory.id}`);
            })
        );
    }

    get layersVisibility() {
        return this.territory.networkSelections;
    }

    get sliceSelection() {
        return this.territory.volumeSelections;
    }

    get volumes() {
        return this.territory.volumesMatrix;
    }

    get rounds() {
        return this.territory.rounds;
    }

    get quotable() {
        if (undefined === this.territory.quotable) {
            return true;
        }
        return this.territory.quotable;
    }

    /**
     * Following logic applies to the empty status of the URBAN layer rounds only
     */
    empties_volume() {
        // account only for rounds that have "selected" property set to true
        const selectedRounds = Object.keys(this.rounds).filter((roundId) => this.rounds[roundId].selected);
        return emptyWalksVolume(selectedRounds, this.sliceSelection[URBAN]);
    }

    empties_rate() {
        const urban_subtotal = this.subtotal(URBAN);
        return urban_subtotal ? `${((this.empties_volume() / this.subtotal(URBAN)) * 100).toFixed(2)}%` : "N/A";
    }
    // END of empty status logic

    destroy() {
        store.dispatch(unsubscribe("territories", `updateAndRenderTable-${this.id}`));
    }

    disconnectedCallback() {
        this.destroy();
    }

    toggleSlice(event) {
        const slice = (event.target.parentElement.dataset || {}).slice;
        const layer = (event.target.dataset || {}).layer;
        if (layer && slice) {
            // mark the selection
            store.dispatch(territoryUpdateVolumeSelections({ layer, slice }));
        }
        if (layer) {
            // toggle layer in the network control
            store.dispatch(
                roundsNetworkToggle({
                    ...store.getState().networkVisibility,
                    [layer]: "true" != event.target.dataset.layerActive,
                })
            );
            //TODO: deselect slices being inactivated - might be better to do from reducer?
        }

        isTerritoryTargeted(this.territory.id) ? targetActiveTerritory() : null;
    }

    subtotal(layer) {
        return layerSubtotal(this.sliceSelection[layer], this.volumes[layer]);
    }

    total() {
        return this.layersVisibility.reduce((acc, layer) => {
            return acc + this.subtotal(layer);
        }, 0);
    }

    optimizeEmpties() {
        const emptiesPercentage = parseFloat(this.emptiesThreshold) / 100;
        const updatedRounds = adjustSelectionToRatio(
            clonedeep(this.rounds),
            this.sliceSelection[URBAN],
            emptiesPercentage
        );
        store.dispatch(territoryUpdateRoundsSelection(updatedRounds, this.territory.id));
        document.dispatchEvent(reduxActiveRoundsUpdateEvent);
    }

    percent(val1, val2) {
        return val2 ? `${parseInt((100 * val1) / val2)}%` : "";
    }

    textHidden(id) {
        return this.layersVisibility.includes(id) ? "bg-sunrise" : "";
    }

    selected(layer, slice) {
        return this.sliceSelection[layer] && this.sliceSelection[layer].includes(slice) ? "bg-sunrise text-black" : "";
    }

    validateEmptiesInput(e) {
        const input = e.target;
        input.value = input.value.replace(/[^0-9]/g, "");

        if (input.value !== "") {
            let value = parseInt(input.value);
            value = Math.min(99, value);
            input.value = value.toString();
        }
    }

    validateEmptiesInputOnBlur(e) {
        const input = e.target;
        if (input.value !== "") {
            let value = parseInt(input.value);
            value = Math.max(0, Math.min(99, value));
            this.emptiesThreshold = value.toString();
        }
        input.value = this.emptiesThreshold;
    }

    render() {
        return html`
        <style>@import 'main.css';</style>
        <table class="w-full select-none" @click=${(e) => this.toggleSlice(e)}>
            <colgroup>
                <col class="border-r border-dark-grey"></col>
                <col class="w-1/5 border-r border-dark-grey"></col>
                <col class="w-1/5 border-r border-dark-grey"></col>
                <col class="w-1/5 border-r border-dark-grey"></col>
                <col class="w-1/5"></col>
            </colgroup>
            <tr class="">
                <th class="w-16 py-4"></th>
                <th data-layer="${URBAN}" data-layer-active="${!this.textHidden(
            URBAN
        )}" class="text-sm uppercase font-medium ${this.textHidden(URBAN)}">Reach</th>
                <th data-layer="${POSTIES}" data-layer-active="${!this.textHidden(
            POSTIES
        )}" class="text-sm uppercase font-medium ${this.textHidden(POSTIES)}">Postie</th>
                <th data-layer="${RURAL}" data-layer-active="${!this.textHidden(
            RURAL
        )}" class="text-sm uppercase font-medium ${this.textHidden(RURAL)}">Rural</th>
                <th data-layer="${POBOXES}" data-layer-active="${!this.textHidden(
            POBOXES
        )}" class="text-sm uppercase font-medium ${this.textHidden(POBOXES)}">Boxes</th>
            </tr>
            <tr data-slice="${INCLUSIVE}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Incl(All)</td>
                <td data-layer="${URBAN}" class="text-sm cursor-pointer ${this.selected(URBAN, INCLUSIVE)}">${
            this.volumes[URBAN][INCLUSIVE]
        }</td>
                <td data-layer="${POSTIES}" class="text-sm cursor-pointer ${this.selected(POSTIES, INCLUSIVE)}">${
            this.volumes[POSTIES][INCLUSIVE]
        }</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr data-slice="${EXCLUSIVE}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Excl</td>
                <td data-layer="${URBAN}" class="text-sm cursor-pointer ${this.selected(URBAN, EXCLUSIVE)}">${
            this.volumes[URBAN][EXCLUSIVE]
        }</td>
                <td data-layer="${POSTIES}" class="text-sm cursor-pointer ${this.selected(POSTIES, EXCLUSIVE)}">${
            this.volumes[POSTIES][EXCLUSIVE]
        }</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr data-slice="${NEWSPAPER_ALL}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">News(All)</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
                <td data-layer="${RURAL}" class="text-sm cursor-pointer ${this.selected(RURAL, NEWSPAPER_ALL)}">${
            this.volumes[RURAL][NEWSPAPER_ALL]
        }</td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr data-slice="${NEWSPAPER_INCLUSIVE}" class="text-center hover:bg-light-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">News(Incl)</td>
                <td data-layer="${URBAN}" class="text-sm cursor-pointer ${this.selected(URBAN, NEWSPAPER_INCLUSIVE)}">${
            this.volumes[URBAN][NEWSPAPER_INCLUSIVE]
        }</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr data-slice="${NEWSPAPER_EXCLUSIVE}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">News(Excl)</td>
                <td data-layer="${URBAN}" class="text-sm cursor-pointer ${this.selected(URBAN, NEWSPAPER_EXCLUSIVE)}">${
            this.volumes[URBAN][NEWSPAPER_EXCLUSIVE]
        }</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr data-slice="${RESIDENTIAL}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Resid.</td>
                <td class="bg-bluesteel" title="Exclusive to inclusive rate">
                    <div class="text-sm text-black">${this.percent(
                        this.volumes[URBAN][EXCLUSIVE],
                        this.volumes[URBAN][INCLUSIVE]
                    )}</div>
                </td>
                <td class="bg-bluesteel" title="Exclusive to inclusive rate">
                    <div class="text-sm text-black">${this.percent(
                        this.volumes[POSTIES][EXCLUSIVE],
                        this.volumes[POSTIES][INCLUSIVE]
                    )}</div>
                </td>
                <td data-layer="${RURAL}" class="text-sm cursor-pointer ${this.selected(RURAL, RESIDENTIAL)}">${
            this.volumes[RURAL][RESIDENTIAL]
        }</td>
                <td data-layer="${POBOXES}" class="text-sm cursor-pointer ${this.selected(POBOXES, RESIDENTIAL)}">${
            this.volumes[POBOXES][RESIDENTIAL] + ((this.volumes[COUNTERS] || {})[RESIDENTIAL] || 0)
        }</td>
            </tr>
            <tr data-slice="${BUSINESS}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Busin.</td>
                <td class="bg-bluesteel"></td>
                <td data-layer="${POSTIES}" class="text-sm cursor-pointer ${this.selected(POSTIES, BUSINESS)}">${
            this.volumes[POSTIES][BUSINESS]
        }</td>
                <td class="bg-bluesteel"></td>
                <td data-layer="${POBOXES}" class="text-sm cursor-pointer ${this.selected(POBOXES, BUSINESS)}">${
            this.volumes[POBOXES][BUSINESS]
        }</td>
            </tr>
            <tr data-slice="${FARMER}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Farmer</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
                <td data-layer="${RURAL}" class="text-sm cursor-pointer ${this.selected(RURAL, FARMER)}">${
            this.volumes[RURAL][FARMER]
        }</td>
                <td data-layer="${POBOXES}" class="text-sm cursor-pointer ${this.selected(POBOXES, FARMER)}">${
            this.volumes[POBOXES][FARMER] + ((this.volumes[COUNTERS] || {})[FARMER] || 0)
        }</td>
            </tr>
            <tr data-slice="${DAIRY}" class="text-center hover:bg-sunrise border-t border-dark-grey">
                <td class="text-sm text-left">Dairy</td>
                <td class="bg-bluesteel"></td>
                <td class="bg-bluesteel"></td>
                <td data-layer="${RURAL}" class="text-sm cursor-pointer ${this.selected(RURAL, DAIRY)}">${
            this.volumes[RURAL][DAIRY]
        }</td>
                <td class="bg-bluesteel"></td>
            </tr>
            <tr class="text-right border-t border-dark-grey">
                <td class="text-sm text-left">Subtotal</td>
                <td class="text-sm pr-1 ${this.textHidden(URBAN)}">${this.subtotal(URBAN)}</td>
                <td class="text-sm pr-1 ${this.textHidden(POSTIES)}">${this.subtotal(POSTIES)}</td>
                <td class="text-sm pr-1 ${this.textHidden(RURAL)}">${this.subtotal(RURAL)}</td>
                <td class="text-sm pr-1 ${this.textHidden(POBOXES)}">${
            this.subtotal(POBOXES) + this.subtotal(COUNTERS)
        }</td>
            </tr>
            ${
                this.clientView
                    ? html``
                    : html` <tr class="text-right border-t border-dark-grey">
                          <td class="text-sm text-left">Empties</td>
                          <td colspan="2" class="text-center text-sm select-text pr-1 ${this.textHidden(URBAN)}">
                              ${this.empties_rate()} (${this.empties_volume()})
                          </td>
                          <td colspan="2" class="text-right py-1">
                              <input
                                  title="Set the empties threshold to desired value (percentage)"
                                  type="text"
                                  inputmode="numeric"
                                  pattern="[0-9]*"
                                  @input=${this.validateEmptiesInput}
                                  @blur=${this.validateEmptiesInputOnBlur}
                                  .value=${this.emptiesThreshold || ""}
                                  class="inline-block text-sm text-center border w-6 align-middle focus:outline-none"
                              />
                              <span class="inline-block text-sm pr-1 align-middle">%</span>
                              <button
                                  title="Update the empties selection based on the threshold value"
                                  @click=${() => this.optimizeEmpties()}
                                  class="inline-block text-sm px-2 rounded-lg border border-peppermint hover:bg-peppermint-50 align-middle"
                              >
                                  Update
                              </button>
                          </td>
                      </tr>`
            }
            <tr class="text-right border-t border-dark-grey ${this.quotable ? nothing : "bg-unquotable text-white"}">
                <td class="text-sm text-left font-medium">Total</td>
                ${
                    this.quotable
                        ? html` <td colspan="4" class="pr-1">${this.total()}</td> `
                        : html`
                              <td colspan="2" class="text-center text-sm">Not quotable</td>
                              <td colspan="2" class="pr-1">${this.total()}</td>
                          `
                }
            </tr>
        </table>
        `;
    }
}

global.customElements.define("rm-territory-meta-table", TerritoryMetaTableComponent);
